.about {
  flex-direction: column;
  margin-top: 3em;
}

.about__name {
  color: var(--clr-primary);
}

.about__role {
  margin-top: 1.2em;
}

.about__desc {
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0px;
  padding: 0px;
  
}

.about__desc,
.about__contact {
  margin-top: 3.4em;
  align-items: center;
}

.about .link--icon {
  margin-right: 0.8em;
  font-size: 25px;
}

.about .btn--outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .app .about {
    align-items: center;
    margin-top: 2em;
  }
}

.about__link__margins {
  margin: 30px;
}

.about__center__div {
  margin: auto;
  width: 50%;
  padding: 10px;
}